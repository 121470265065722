import React from "react";
import "./Linkscreen.css";
import skkPhoto from "../../assets/pictures/skktwo.jpeg";
import eurogroom from "../../assets/pictures/eurogroom.jpeg";
import svenskaterrier from "../../assets/pictures/svenskaterrier.jpeg";
import swtk from "../../assets/pictures/swtk.jpeg";
const LinkScreen = () => {
  return (
    <div className="root">
      <h1>Våra samarbetspartners</h1>
      <div className="ram">
        <div className="firstcontainer">
          <a href="https://www.skk.se">
            <img src={skkPhoto} width={195} height={195} alt="" />
          </a>
          <a href="https://www.terrierklubben.se">
            <img src={svenskaterrier} width={195} height={195} alt="" />
          </a>
          <a href="https://www.swtk.se">
            <img src={swtk} width={195} height={195} alt="" />
          </a>
        </div>
        <div className="eurogroom">
          <a href="https://www.eurogroom.se">
            <img src={eurogroom} width={405} height={75} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LinkScreen;
