import React from "react";
import Card from "../globals/Card";
import "./MyDogs.css";
import Bella from "../../assets/pictures/Bella.jpg";
import Wera from "../../assets/pictures/Wera.png";
import Helix from "../../assets/pictures/helix.jpg";
import Bellman from "../../assets/pictures/bellman.jpg";
import MySweet from "../../assets/pictures/mysweet.jpg";
import Maja from "../../assets/pictures/macy1.jpg";
const MyDogs = () => {
  const dummy_data = [
    {
      name: "Bellman",
      description: "SÖ Lovely Lugh SE51477/2019",
      imageUrl: Bellman,
    },
    {
      name: "Helix",
      description: "SÖ Keen On Kaspian SE16408/2019",
      imageUrl: Helix,
    },
    {
      name: "Bella",
      description: "SÖ Just Call Me Jingles SE43355/2017",
      imageUrl: Bella,
    },
    {
      name: "Wera",
      description: "SÖ I Like ISolde SE42394/2016",
      imageUrl: Wera,
    },
    {
      name: "Macy",
      description: "SÖ My Sweet Macy SE39501/2020",
      imageUrl: MySweet,
    },
    {
      name: "Maja",
      description: "Irish Mesmerize`s Liban SE55942/2021",
      imageUrl: Maja,
    },
  ];
  return (
    <>
      <div className="container">
        {dummy_data.map((dog) => (
          <Card
            title={dog.name}
            imageUrl={dog.imageUrl}
            body={dog.description}
          />
        ))}
      </div>
    </>
  );
};

export default MyDogs;
