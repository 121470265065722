import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(null);
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <nav className="navbar">
      <h3 className="logo" onClick={refreshPage}>
        Softy Österlen
      </h3>
      <ul
        className={isMobile ? "nav-links-mobile" : "nav-links"}
        onClick={() => setIsMobile(false)}
      >
        <Link to="/" className="home">
          <li>Hem</li>
        </Link>
        <Link to="/puppynews" className="puppynews">
          <li>Valp Nytt</li>
        </Link>
        <Link to="/mydogs" className="mydogs">
          <li>Mina Hundar</li>
        </Link>
        <Link to="/links" className="links">
          <li>Länkar</li>
        </Link>
      </ul>
      <button
        className="mobile-menu-icon"
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? (
          <i className="fas fa-times"></i>
        ) : (
          <i className="fas fa-bars">
            <ul
              className={isMobile ? "nav-links-mobile" : "nav-links"}
              onClick={() => setIsMobile(false)}
            >
              <Link to="/" className="home">
                <li>Hem</li>
              </Link>
              <Link to="/puppynews" className="puppynews">
                <li>Valp Nytt</li>
              </Link>
              <Link to="/mydogs" className="mydogs">
                <li>Mina Hundar</li>
              </Link>
              <Link to="/links" className="links">
                <li>Länkar</li>
              </Link>
            </ul>
          </i>
        )}
      </button>
    </nav>
  );
};

export default Navbar;
