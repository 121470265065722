import React from "react";
import firstPagePhoto from "../../assets/pictures/startsida.jpeg";
import "./Homescreen.css";
import TextCard from "../globals/TextCard";

const HomeScreen = () => {
  return (
    <div className="root">
      <div className="headertag">
        <h2>Softy Österlen</h2>
      </div>
      <div className="cards">
        <TextCard>
          <h1>Om oss</h1>
          <p>
            Jag heter Ingrid Nilsson, jobbar som sjuksköterska på natten. Detta
            ger mig utrymme att hålla på med min stora hobby, Mina Wheatens.
            Mina första 8 år växte jag upp på en gård med hästar, kor, grisar,
            kycklingar och inte minst hundar och katter. Jag och mina 5 syskon
            har alla haft eller har hundar. Det har blivit många olika raser
            genom åren. Själv hade jag en Golden och en Cairn terrier. Men jag
            blev helsåld på Wheaten då min tvillingsyster och min äldre bror
            köpte vars ett kullsyskon. 2001 köpte jag min Mitzy ( efter Pobe-s
            Cornelius och Cennacopp´s Nirvana) och började ställa ut henne. Blev
            biten och fortsatte på den vägen. 2005 startade jag min kennel som
            fick namnet Softy Österlen..
          </p>
        </TextCard>
        <TextCard>
          <h1>Arbetssätt</h1>
          <p>
            Jag har haft tur och tydligen en bra linje med hundar som gett mig
            otroligt tillgivna wheatens. Men de får också mycket tid och kärlek.
            Mina valpar föds och tillbringar sina 8 veckor inne hos oss, som
            vilken familjemedlem som helst. De får den  trygghet och sociala
            stimulans de behöver för att sen flytta vidare till sina egna
            familjer och förgylla tillvaron för dem. Föräldrarna till valparna
            är röntgade med godkända höfter / armbågar. De är oftast testade för
            PLE/PLN, Microphalmia och har gjort genomförd BPH ( Beteende-och
            personlighetsbeskrivning hund). Denna beskrivning rekommenderar jag
            alla valpköpare att göra. Man får reda på hur hunden reagerar i
            olika situationer. Jag säljer mina hundar enl SKK rekommendationer
            och regler. Det finns mycket bra information om rasen  på
            wheatenklubbens hemsida. swtk.se
          </p>
        </TextCard>
      </div>
      <div className="imagecontainerr">
        <img src={firstPagePhoto} width={"55%"} height={625} alt="" />
      </div>
      <div className="socialmedia">
        <p>Kontakta oss gärna på följande länkar nedan.</p>
        <a href="mailto:ingrid@softyosterlen.se">ingrid@softyosterlen.se</a>

        <a href="https://www.instagram.com/softyosterlen">
          www.instagram.com/softyosterlen
        </a>
        <a href="tel: 070557113">0705557113</a>
      </div>
    </div>
  );
};

export default HomeScreen;
