import React from "react";
import Navbar from "./components/navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./components/screens/Homescreen";
import PuppyNews from "./components/screens/PuppyNews";
import MyDogs from "./components/screens/MyDogs";
import Links from "./components/screens/Linkscreen";
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/puppynews" element={<PuppyNews />} />
        <Route path="/mydogs" element={<MyDogs />} />
        <Route path="/links" element={<Links />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
