import React from "react";
import "./PuppyNews.css";
import Macy from "../../assets/pictures/macy-new.jpg";
import Macy2 from "../../assets/pictures/macy-new-two.jpg";

const PuppyNews = () => {
  return (
    <div className="root">
      <div className="imagecontainer">
        <img src={Macy} width={555} height={455} alt="valpnytt 3" />
        <p>Macy (Softy Österlen My Sweet Macy) Regnr SE39501/2020</p>
        <img src={Macy2} width={555} height={455} alt="valpnytt 3" />
        <p>Lakkas Roxbury Russet Regnr SE21734/2018</p>
      </div>
      <p>
        Planerar valpar till våren med dessa godingar Macy (Softy Österlen My
        Sweet Macy) Regnr SE39501/2020 och Lakkas Roxbury Russet Regnr
        SE21734/2018
      </p>
    </div>
  );
};

export default PuppyNews;
